
:root {
    --papi-bg: black;
    --papi-bg2: #282828;
    --papi-fg: #00ff00;
    --papi-hl: white;
    --papi-font: monospace;
}

#papi {
    background: var(--papi-bg);
    color: var(--papi-fg);
    position: fixed;
    height: 100%;
    width: calc(100% - 1em);
    font-family: var(--papi-font);
    overflow: scroll;
    padding: .5em;
}

#papi button {
    padding: 0 .5em;
    background-color: var(--papi-bg);
    color: var(--papi-hl);
    font-family: var(--papi-font);
    border: 1px solid var(--papi-hl);
    box-sizing: border-box;
    text-transform: uppercase;
}
#papi button:active {
    background-color: var(--papi-bg);
    color: var(--papi-hl);
    font-family: var(--papi-font);
    border: 1px dashed var(--papi-hl);
}
#papi button:disabled {
    opacity: 0.33;
}
#papi button:hover {
    text-decoration: underline;
}

#papi input {
    padding: 0 .5em;
    background-color: var(--papi-bg);
    color: var(--papi-hl);
    font-family: var(--papi-font);
    border: 1px dashed var(--papi-fg);
    box-sizing: border-box;
}
#papi input:hover {
    border: 1px dashed var(--papi-hl);
}
#papi input:focus, #papi input:active {
    border: 1px solid var(--papi-hl);
}

#papi input[type=checkbox] {
    display: none;
}
#papi input[type=checkbox] + label {
    padding: 0 .5em;
    border: 1px solid #0f0;
}
#papi input[type=checkbox]:checked + label {
    color: var(--papi-hl);
    border: 1px solid var(--papi-hl);
}
#papi input[type=checkbox]:active + label {
    color: var(--papi-hl);
    border: 1px dashed var(--papi-hl);
}
#papi input[type=checkbox]:hover + label {
    text-decoration: underline;
}

.cerditos-menu {
    display: float;
    float: right;
    margin-top: -2.5em;
}

.candidate p {
    padding: 0;
    margin: 0;
}

.candidates li {
    padding: .5em 0;
    margin: 0;
}

.bank button {
    display: float;
    float: right;
    margin-top: -2em;
}

.pigs {
    list-style: none;
    padding: 0;
    width: 100%;
}
.pigs .pig-header {
    line-height: 1.5em;
}
.pigs .pig-header-kind {
    width: 1.25em;
    height: 1.25em;
    border-radius: 1em;
    display: inline-block;
    margin-right: .5em;
    margin-left: -.5em;
    margin-bottom: -.25em;
}
.pigs p {
    display:table;
    padding: 0;
    margin: 0;
    width: 100%;
    box-sizing: border-box;
}
.pig-controls {
    display: block;
    position: absolute;
    right: 1em;
    margin-top: -1.5em;
}
.pig-tag {
    color: var(--papi-hl);
    opacity: 0.5;
}
.pig-label {
    display: table-cell;
    padding: .25em 0;
    width: 4em;
    color: var(--papi-hl);
    opacity: 0.5;
}
.pigs input {
    display: table-cell;
    width: 100%;
}
.pigs li {
    padding: .25em .5em .25em .75em;
    margin: .125em 0;
    background: var(--papi-bg2);
}
.pigs li {
    border: 1px solid var(--papi-bg);
}
.pigs li:hover {
    border: 1px solid color-mix(in srgb, var(--papi-hl), transparent 50%);
}
.pigs li.selected{
    border: 1px solid var(--papi-hl);
}
.pigs li.selected span{
    color: var(--papi-hl);
}

.selected {
    font-weight: bold;
}
