
:root {
    --bebe-bg: #FFD4F2;
    --bebe-fg: #C20085;
    --bebe-font: Caveat;
    --bebe-radius1: 1em;
    --bebe-radius2: .5em;
}

body {
    background-color: #C2EBFF;
}

#bebe {
    color: var(--bebe-fg);
    font-size: 24px;
    position: absolute;
    height: 100%;
    width: 100%;
    font-family: var(--bebe-font);
    overflow: scroll;
    background-image: url("pic/backs.svg");
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
}
#bebe:before {
    content: '';
    position: absolute;
    z-index: -100;
    width: 100%;
    height: 45%;
    bottom: 0;
    background-color: #D8E6AB;
    box-shadow: 0 0 2em 2em #D8E6AB;
}

.lock {
    font-size: 2.5em;
    position: absolute;
    top: .175em;
    left: .125em;
    text-shadow:
        /* first layer at 1px */
        -1px -1px 0px white,
        0px -1px 0px white,
        1px -1px 0px white,
        -1px  0px 0px white,
        1px  0px 0px white,
        -1px  1px 0px white,
        0px  1px 0px white,
        1px  1px 0px white,
        /* second layer at 2px */
        -2px -2px 0px white,
        -1px -2px 0px white,
        0px -2px 0px white,
        1px -2px 0px white,
        2px -2px 0px white,
        2px -1px 0px white,
        2px  0px 0px white,
        2px  1px 0px white,
        2px  2px 0px white,
        1px  2px 0px white,
        0px  2px 0px white,
        -1px  2px 0px white,
        -2px  2px 0px white,
        -2px  1px 0px white,
        -2px  0px 0px white,
        -2px -1px 0px white;
}

.popup-container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.popup {
    border: 2px solid var(--bebe-fg);
    border-radius: var(--bebe-radius1);
    background-color: var(--bebe-bg);
    padding: 0.5em;
    position: absolute;
    margin: 2em;
    text-align: center;
    z-index: 1000;
}
.popup.dream {
    background-color: var(--bebe-fg);
    color: var(--bebe-bg);
    font-weight: bold;
}


.popup p {
    margin: 0;
    padding: .5em;
}

.popup p.annotation {
    font-size: .8em;
    border-top: 1px solid var(--bebe-fg);
}

#bebe button {
    display: inline-block;
    text-align: center;
    padding: .125em .7em .125em .5em;
    margin-bottom: .5em;
    margin-left: .25em;
    margin-right: .25em;
    border-radius: 3em;
    background-color: var(--bebe-bg);
    color: var(--bebe-fg);
    font-family: var(--bebe-font);
    font-size: 1em;
    border: 1.5px solid var(--bebe-fg);
    box-sizing: border-box;
    text-transform: uppercase;
}
#bebe button:hover {
    box-shadow: 0 0 .125em .125em var(--bebe-fg);
}
#bebe button:active {
    background-color: var(--bebe-bg);
    color: var(--bebe-fg);
    font-family: var(--bebe-font);
    box-shadow: 0 0 .25em .25em var(--bebe-fg);
}
#bebe button:disabled {
    opacity: 0.33;
}


#bebe .dream button {
    background-color: var(--bebe-fg);
    color: var(--bebe-bg);
    border-color: var(--bebe-bg);
}
#bebe .dream button:hover {
    box-shadow-color: var(--bebe-bg);
}
#bebe .dream button:active {
    background-color: var(--bebe-fg);
    color: var(--bebe-bg);
    border-color: var(--bebe-bg);
    box-shadow-color: var(--bebe-bg);
}

#bebe button.important {
    background-color: var(--bebe-fg);
    color: var(--bebe-bg);
    border-color: var(--bebe-fg);
}
#bebe button.imporant:hover {
}
#bebe button.imporant:active {
    background-color: var(--bebe-bg);
    color: var(--bebe-fg);
    border-color: var(--bebe-fg);
}

.coins {
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
}

.coin {
    display: inline-block;
    width: 2em;
    height: 2em;
    border-radius: 2em;
    margin: -.125em .125em;
    background-color: #aaa;
    background-image: url("pic/pig-pad-color.svg");
    background-size: cover;
    border: 2px solid white;
    box-sizing: border-box;
}

.coin.candidate {
    box-shadow: 0 0 3px 3px var(--bebe-fg);
}

.popup .coin {
    margin: 0;
    width: 3em;
    height: 3em;
    border: 1.75px solid var(--bebe-fg);
    position: absolute;
    top: -1.75em;
    left: calc(50% - 1.5em);
}

.popup .coin + p {
    margin-top: .25em;
}

.kind-yellow {
    background-color: #E1FF41 !important;
}
.kind-pink {
    background-color: #F9B7C4 !important;
}
.kind-green {
    background-color: #83F2C3 !important;
}
.kind-lila {
    background-color: #D8C4FF !important;
}
